import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../layouts/AuthGuard/Authguard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />,
    },
    {
      path: 'auth',
      element: <Login />,
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        { path: 'login', element: <Login /> },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/dashboard" replace />, index: true },
        { path: 'dashboard', element: <PageOne /> },
        { path: 'borrowrequest', element: <Requesttojoin /> },
        { path: 'Addborrower', element: <Addcsp /> },
        { path: 'Borrower', element: <PageTwo /> },
        { path: 'profile', element: <Profile /> },
        { path: 'changepassword', element: <Changepassword /> },
        { path: 'Borrower/:id', element: <Updateborrower /> },
        { path: 'CSP/:id', element: <PageThree /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: 'four', element: <PageFour /> },
            { path: 'five', element: <PageFive /> },
            { path: 'six', element: <PageSix /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
const Login = Loadable(lazy(() => import('../pages/Auth/Login')));
const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
const Requesttojoin = Loadable(lazy(() => import('../pages/Requesttojoin')));
const Addcsp = Loadable(lazy(() => import('../pages/Addcsp')));
const Profile = Loadable(lazy(() => import('../pages/Profile')));
const Changepassword = Loadable(lazy(() => import('../pages/Changepassword')));
const Updateborrower = Loadable(lazy(() => import('../pages/Updateborrower')));
const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
