import { createSlice } from '@reduxjs/toolkit';

import toast from 'react-hot-toast';
import axios from '../../Utilities/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  User: [],
};

const slice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    logout(state) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.User = [];
    },
    // GET depart
    getAttrSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.User = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { startLoading, logout } = slice.actions;
// ----------------------------------------------------------------------

export function Logins(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/login', data);
      if (response.status === 200) {
        if (response?.data?.data?.user?.role?.name === 'csp') {
          window.location.href = '/dashboard/dashboard';
          console.log(response, 'login');

          dispatch(slice.actions.getAttrSuccess(response?.data?.data));
          toast.success(response?.data?.data?.message);
        }
      } else {
        toast.error('some thing went wrong');
      }

      return response;
    } catch (error) {
      console.log(error, 'loginerror');
      toast.error(error.message);
      // if (error?.errors && error.errors.length > 0) {
      //   error.errors.forEach((e) => {
      //     toast.error(e);
      //   });
      // }

      throw error.errors;
    }
  };
}

// ----------------------------------------------------------------------
// export function Register(data) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post("/register", data);
//      return response
//     } catch (error) {
//       console.log(error, "errore");
//       throw error.errors;
//     }
//   };
// }
